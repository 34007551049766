var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',{staticClass:"product-auction"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-breadcrumb',{attrs:{"items":_vm.items}}),_c('ServerTime')],1),_c('b-row',[_c('div',{staticClass:"d-flex head"},[_c('h5',[_vm._v("Tài sản sắp được đấu giá")]),_c('b-form',{staticClass:"form-search",on:{"submit":function($event){$event.preventDefault();return _vm.getListAssetForMember(_vm.params)}}},[_c('b-form-group',{attrs:{"id":"input-group-1","label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":"input-1","placeholder":"Tìm kiếm tài sản đấu giá"},model:{value:(_vm.params.asset_name),callback:function ($$v) {_vm.$set(_vm.params, "asset_name", $$v)},expression:"params.asset_name"}}),_c('img',{staticClass:"search_ico",attrs:{"src":require("@/assets/icon/Search.png"),"alt":""},on:{"click":function($event){return _vm.getListAssetForMember(_vm.params)}}})],1)],1)],1),_c('div',{staticClass:"col-12"},[_c('carousel',{key:_vm.carouselKey,attrs:{"responsive":{
                    0: {
                        nav: false,
                        dots: false,
                        items: 1
                    },
                    645: {
                        items: 2,
                        nav: false,
                        dots: false,
                    },
                    768: {
                        items: 2,
                        nav: false,
                        dots: false,
                    },
                    992: {
                        items: 3,
                        nav: false,
                        dots: false,
                    },
                    1200: {
                        items: 4,
                        nav: false,
                        dots: false,
                    },
                },"autoplay":true,"margin":10}},_vm._l((_vm.listAsset.result),function(item,index){return _c('b-link',{key:index},[_c('div',{staticClass:"scroll-mobie d-flex justify-content-center",on:{"click":function($event){_vm.$router.push('/product-detail/' + _vm.encode(item.asset_id))}}},[_c('cardAsset',{attrs:{"item":item}})],1)])}),1)],1)]),_c('h5',{staticClass:"category"},[_vm._v("Danh mục đấu giá")]),_vm._l((_vm.listCategory),function(cate,index){return _c('assetByCategory',{key:index,staticStyle:{"margin-bottom":"38px","justify-content":"center"},attrs:{"cate":cate}})}),(_vm.listNews.result)?_c('div',{staticClass:"mb-2"},[_c('h5',[_vm._v("Tin tức và thông báo mới")]),_c('div',{staticClass:"slide news"},[_c('carousel',{attrs:{"responsive":{
                    0: {
                        nav: false,
                        dots: false,
                        items: 1
                    },
                    425: {
                        items: 2,
                        nav: false,
                        dots: false,
                    },
                    768: {
                        items: 2,
                        nav: false,
                        dots: false,
                    },
                    992: {
                        items: 3,
                        nav: false,
                        dots: false,
                    },
                    1200: {
                        items: 4,
                        nav: false,
                        dots: false,
                    },
                },"autoplay":false,"margin":10}},_vm._l((Array.from(_vm.listNews.result)),function(item,index){return _c('div',{key:index,staticClass:"slide-item"},[_c('cardNew',{attrs:{"item":item}})],1)}),0)],1)]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }