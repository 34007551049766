import { render, staticRenderFns } from "./networkError.vue?vue&type=template&id=70efdf54&scoped=true&"
import script from "./networkError.vue?vue&type=script&lang=js&"
export * from "./networkError.vue?vue&type=script&lang=js&"
import style0 from "./networkError.vue?vue&type=style&index=0&id=70efdf54&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70efdf54",
  null
  
)

export default component.exports