<template>
    <div>
        <b-container class="product_detail">
            <div class="d-flex justify-content-between">
                <b-breadcrumb :items="items"></b-breadcrumb>
                <ServerTime></ServerTime>
            </div>
            <b-row>
                <h5 class="title" v-if="dataAsset.asset_name">{{ dataAsset.asset_name }}</h5>
                <b-col cols="lg-6">
                    <slideImage :list_images=dataAsset.image :carouselKey="carouselKey"/>
                    <div>
                        <img src="@/assets/icon/Auction2.png" alt="" class="image-status" v-if="!dataAsset.auction_status">
                        <img src="@/assets/icon/golden sold out.png" alt="" class="image-status" v-else>
                    </div>
                    <b-row class="file ">
                        <b-col cols="md-6">
                            <div class="more-info">
                                <h5 class="more-info__ttl">Tài liệu liên quan</h5>
                                <ul class="list-info">
                                    <li v-for="(  file, index  ) in   dataAsset.file  ">
                                        <b-link :href="$storage + file.url" target="_blank">{{ file.file_name }}</b-link>
                                    </li>
                                </ul>
                            </div>
                        </b-col>
                        <b-col cols="md-6" class="mb-2">
                            <div class="more-info">
                                <h5 class="more-info__ttl">Thông tin tổ chức đấu giá</h5>
                                <div class="col-12 d-flex">
                                    <img class="col-2 mr-2" style="width: 35px; height: 25px;"
                                        src="@/assets/icon/Company.png" alt="">
                                    <div>
                                        <span>
                                            Đơn vị tổ chức:
                                        </span> <br>
                                        <label class="text-red">
                                            {{ dataAsset.org }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 d-flex">
                                    <img class="col-2 mr-2" style="width: 35px; height: 25px;"
                                        src="@/assets/icon/Customer.png" alt="">
                                    <div>
                                        <span>
                                            Đấu giá viên:
                                        </span> <br>
                                        <label class="text-red">
                                            {{ dataAsset.DGV }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 d-flex">
                                    <img class="col-2 mr-2" style="width: 35px; height: 25px;"
                                        src="@/assets/icon/Location.png" alt="">
                                    <div>
                                        <span>
                                            Địa điểm:
                                        </span> <br>
                                        <span class="text-red">
                                            {{ dataAsset.auction_address }}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="lg-6">
                    <p class="start-at" v-if="dataAsset.asset_status == 0 && !showCountDown">
                        Cuộc đấu giá sẽ bắt đầu lúc {{ formateTime(dataAsset.start_time,'DD/MM/YYYY HH:mm:ss') }}
                    </p>
                    <div class="show-time" v-else-if="dataAsset.asset_status == 0 && showCountDown">
                            <p>Cuộc đấu giá sắp diễn ra</p>
                            <p>Thời gian đến đấu giá còn: </p>
                            <p>{{ countdown }}</p>
                        </div>
                    <div class="show-time" v-else-if="dataAsset.asset_status == 1">
                        <p>Cuộc đấu giá đang diễn ra</p>
                        <p>Thời gian trả giá còn: </p>
                        <p>{{ countdownEnd }}</p>
                    </div>
                    <div class="status_au" v-else :class="{
                        'status_au--success': dataAsset.auction_status == 2,
                        'status_au--fail': dataAsset.auction_status == 3,
                        'status_au--dif': dataAsset.auction_status == 4
                    }">
                        <p style="padding-top: 5px;">Cuộc đấu giá kết thúc </p>
                        <p v-if="dataAsset.auction_status == 3">Đấu giá không thành</p>
                        <p v-else-if="dataAsset.auction_status == 4" style="color: #ee3439;">Kết thúc</p>
                        <p v-else>Đấu giá thành</p>
                    </div>
                    <div class="col-12 d-flex align-items-center approve-status" v-if="token && dataAsset.asset_status == 0 || dataAsset.asset_status == 1">
                        <p class="success m-0" style="font-size: 14px;" v-if="auctionDetail.is_client == 1">
                            <img src="@/assets/front-end/icon/imp.png" alt="" style="width: 18px;height: 18px;">
                            Đã được mời tham gia đấu giá
                        </p>
                        <div v-if="auctionDetail.is_client == 0">
                            <p class="success m-0" style="font-size: 14px;" v-if="auctionDetail.approve_status == 1">
                                <img src="@/assets/front-end/icon/imp.png" alt="" style="width: 18px;height: 18px;">
                                Đã được duyệt tham gia đấu giá
                            </p>
                            <p class="warning m-0" style="font-size: 14px; font-weight: 600;" v-else-if="auctionDetail.review_status == 0">
                                <img src="@/assets/front-end/icon/Important.png" alt="" style="width: 24px; height: 20px;">
                                    Hồ sơ của bạn đang trong thời gian kiểm duyệt
                            </p>
                            <p class="warning m-0" style="font-size: 14px; font-weight: 600;"
                                @click="$refs.noticeModal.show(dataAsset.asset_id, dataAsset.auction_id)" 
                                v-else-if="auctionDetail.approve_status == 0">
                                <img src="@/assets/front-end/icon/Important.png" alt=""
                                    style="width: 24px; height: 20px;"> Chưa được duyệt tham gia đấu giá
                            </p>
                        </div>
                    </div>
                    <div class="info" v-if="endStatus">
                        <p>Giá trúng cuối cùng:</p> <span class="highlight">
                            {{ parseInt(dataAsset.highestBid).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info">
                        
                        <p>Giá khởi điểm:</p> <span class="highlight">
                            {{ parseInt(dataAsset.price).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info">
                        <p>Mã tài sản đấu giá:</p> <span>{{ dataAsset.asset_code }}</span>
                    </div>
                    <div class="info" v-if="!endStatus">
                        <p>Thời gian bắt đầu đăng ký tham gia đấu giá:</p> <span>{{ formateTime(dataAsset.register_start_time, 'DD/MM/YYYY HH:mm:ss') }}
                        </span>
                    </div>
                    <div class="info" v-if="!endStatus">
                        <p>Thời gian kết thúc đăng ký:</p> <span>{{ formateTime(dataAsset.register_end_time, 'DD/MM/YYYY HH:mm:ss') }}
                        </span>
                    </div>
                    <div class="info">
                        <p>Tiền mua hồ sơ:</p>
                        <span>{{
                            parseInt(dataAsset.record_fee).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info">
                        <p>Bước giá:</p> <span>{{ parseInt(dataAsset.step_price).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info" v-if="!endStatus">
                        <p>Số bước giá trả tối đa / lần:</p> <span>{{ dataAsset.step }}</span>
                    </div>
                    <div class="info">
                        <p>Tiền đặt trước:</p> <span>
                            {{ parseInt(dataAsset.down_payment).toLocaleString("vi-VN") }} VNĐ
                        </span>
                    </div>
                    <div class="info" v-if="!endStatus">
                        <p>Tên chủ tài sản:</p> <span>{{ dataAsset.owner }}</span>
                    </div>
                    <div class="info" v-if="!endStatus">
                        <p>Nơi xem tài sản:</p> <span>{{ dataAsset.view_address }}</span>
                    </div>
                    <div class="info">
                        <p>Thời gian bắt đầu trả giá:</p> <span>{{ formateTime(dataAsset.start_time,'DD/MM/YYYY HH:mm:ss') }}
                        </span>
                    </div>
                    <div class="info">
                        <p>Thời gian kết thúc trả giá:</p> <span>{{ formateTime(dataAsset.end_time, 'DD/MM/YYYY HH:mm:ss') }}
                        </span>
                    </div>
                    <div :class="{'text-right md': !registerStatus,'text-center': registerStatus }">
                        <button class="authorize" 
                        v-if="authorize && auctionDetail.is_client == 0 && dataAsset.authority_exp_status && dataAsset.asset_status == 0 && token && auctionDetail.approve_status" 
                        @click="$refs.authorize.show(dataAsset)">Ủy quyền</button>
                        <b-link :to="'/auction-detail/' + encode(dataAsset.asset_id)" class="btn-link btn-detail">
                            Chi tiết sản phẩm đấu giá</b-link>
                        <b-link :to="'/register-auction/' + encode(dataAsset.asset_id)" class="btn-link btn-auction"
                            v-if="registerStatus && token && dataAsset.asset_status == 0 && dataAsset.register_status == 0 && countdownStatusRegister"
                            >Đăng ký tham gia đấu giá </b-link>
                        <b-link class="btn-link btn-auction" @click="showModal(auctionDetail)" :disabled="auctionDetail.kick_status == 1 || auctionDetail.approve_status == 0"
                            v-if="auctionDetail.approve_status && token && (dataAsset.asset_status == 0 || dataAsset.asset_status == 1)">
                            Tham gia đấu giá</b-link>
                        <b-link @click="$router.push('/payment-history/' + asset_id)" class="btn-link btn-report" 
                        v-if="dataAsset.auction_public_status == 1 && dataAsset.asset_status != 0 && dataAsset.asset_status != 1">
                            Chi tiết lịch sử đấu giá</b-link>
                    </div>
                </b-col>
            </b-row>
            <SameProduct :category_id="dataAsset.category_id" :listAsset="listAsset" @getData="getData"/>
            <notice_modal ref="noticeModal"/>
            <joinAuction ref="joinAuction" />
            <authorize ref="authorize"/>
            </b-container>
    </div>
</template>
<script>
import notice_modal from "./modal/notice_modal.vue";
import joinAuction from "./modal/join_auction.vue";
import SameProduct from "../web-view/components/sameproduct.vue";
import slideImage from "../web-view/components/slideImage.vue"
import authorize from "./modal/authority.vue"
import ServerTime from "./components/serverTime.vue";
export default {
    components: {
        SameProduct,
        slideImage,
        notice_modal,
        joinAuction,
        authorize,
        ServerTime,
    },
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    to: '/',
                },
                {
                    text: 'Thông tin đấu giá',
                    active: true
                },
            ],
            params: {
                asset_name: "",
                asset_code: "",
                asset_status: "",
                category_id: '',
                owner: "",
                from_price: "",
                to_price: "",
                from_step_price: "",
                to_step_price: "",
                page: 1,
                per_page: 10,
                order: "latest",
                winner: "",
                status: "",
                description: "",
                auction_id: "",
            },
            timeLeft: 0,
            timeLeftEnd:0 ,
            isShow: true,
            dataAsset: { start_time:"" },
            asset_id: this.$route.params.asset_id,
            registerStatus:false,
            countdownStatusRegister: false,
            showCountDown:true,
            endStatus:false,
            listAsset:{},
            auctionDetail:{
                is_client: '',
                approve_status:''
            },
            token:localStorage.getItem('member'),
            carouselKey: 0,
            authorize: true,
            interval: null
        }
    },
    computed: {
        countdown() {
            return this.timeLeft ? this.formatTime(this.timeLeft, 'hour') : 'Đợi tiến hành đấu giá ';
        },
        countdownEnd() {
            return this.timeLeftEnd ? this.formatTime(this.timeLeftEnd, 'hour') : 'Chờ kết quả đấu giá ';
        },
    },
    watch: {
        "$route.params": function(url) {
            console.log(url);
            
            clearInterval(this.interval);
            this.getData(this.decode(url.asset_id))
        }
    },
    beforeUpdate(){
        if (this.auctionDetail.approve_status != 0 && this.auctionDetail.approve_status != 1 && this.auctionDetail.is_client == '') {
            this.registerStatus = true
        }else{
            this.registerStatus = false
        }
    },
    mounted() {
        this.getData(this.decode(this.asset_id))
    },
    methods: {
        async getData(id) {
            this.asset_id = this.encode(id)
            await this.$axios.get('/asset/getAssetDetail', {
                params: {
                    asset_id: id
                },
                headers: {
                    'x-access-token': '',
                }
            }).then((res) => {
                this.dataAsset = res.data
                this.carouselKey++;
                this.params.category_id = this.dataAsset.category_id
                if (this.dataAsset.asset_status == 3 || this.dataAsset.asset_status == 4) {
                    this.endStatus = true
                }
                this.setCountdown()
                if (this.token) {
                    this.getAuctionDetailForMember({
                        auction_id: this.dataAsset.auction_id
                    })
                }
            })
            await this.getListAssetForMember(this.params)
        },
        showModal(item) {
            item.auction_id = this.dataAsset.auction_id
            this.$refs.joinAuction.show(item)
            // tắt nhập key để vào lịa phòng
            // if (!item.attendance_status) {
            //     this.$refs.joinAuction.show(item)
            // }
            // else {
            //     this.$router.push('/auction-room/' + this.encode(item.asset_id))
            // }
        },
        startCountdownEnd(duration) {
            let endTime = this.$moment.utc().valueOf() + duration - 25200000;
            this.interval = setInterval(() => {
                let timeLeft = Math.round((endTime - this.$moment.utc().valueOf()));
                if (timeLeft < 0) {
                    clearInterval(this.interval);
                    timeLeft = 0;
                }
                this.timeLeftEnd = timeLeft;
            });
        },
        setCountdown(){
            let startTime = new Date(this.dataAsset.start_time).getTime();
            let endTime = new Date(this.dataAsset.end_time).getTime();
            let now = new Date().getTime();
            let countdown = startTime - now
            if (countdown !== 0 && countdown > 0) {
                this.timeLeft = parseInt(countdown);
                this.startCountdown(this.timeLeft);
            }
            
            let countdownEnd = endTime - now
            if (countdownEnd !== 0 && countdownEnd > 0) {
                this.timeLeftEnd = parseInt(countdownEnd);
                this.startCountdownEnd(this.timeLeftEnd);
            }
            let registerStart = new Date(this.dataAsset.register_start_time).getTime();
            let registerEnd = new Date(this.dataAsset.register_end_time).getTime()
            if (registerStart - 25200000 < now && registerEnd - 25200000 > now) {
                this.countdownStatusRegister = true
            } else {
                this.countdownStatusRegister = false
            }
            if (countdown > 604800000) {
                this.showCountDown = false
            }
        },
        
    },
    
}
</script>
<style lang="css" scoped>
.authorize{
    border-radius: 16.019px;
    background: #EE3439;
    width: 101.872px;
    height: 46.455px;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16.019px;
    font-weight: 500;
    border: unset;
    margin-right: 11px;
}
.product_detail {
    color: #515151;
    background-image: url('@/assets/images/logo_moreinfo.png');
    background-repeat: no-repeat;
    background-position: top;
}
.status_au {
    box-shadow: 0px 2px 10px 0px #0000001A;
    border-radius: 40px;
    text-align: center;
    position: relative;
    margin-bottom: 15px;
}
.approve-status{
    padding-top: 0.25rem;
    height: 15px;
}
.status_au::before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    width: 41px;
    height: 41px;
    left: 3%;
    top: 23%;
}

.status_au p {
    color: #515151;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;
}

.status_au p:last-child {
    font-family: Libre Bodoni;
    font-weight: 700;
    font-size: 26px;
    padding-bottom: 10px;
}

.status_au--success::before {
    background-image: url('@/assets/icon/Ok.png');
}

.status_au--fail::before {
    background-image: url('@/assets/front-end/icon/Group\ 831.png');
    width: 52px;
    height: 51px;
}

.status_au--dif::before {
    background-image: url('@/assets/icon/Auction_modal.png');
    width: 52px;
    height: 51px;
    background-size: cover;
}

.status_au--success p:last-child {
    color: #00B569;
}

.status_au--fail p:last-child {
    color: #EE3439;
}

.btn-link.btn-report {
    border-color: #EE3439;
    color: #EE3439;
    margin-left: 15px;
}

.image-status {
    position: absolute;
    z-index: 90;
    top: 5%;
    left: 5%;
}

.btn-complain {
    background: none;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #515151;
    border-radius: 16px;
    width: 100%;
    padding: 10px 0;
    color: #515151;
}

.info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.info p {
    min-width: 330px;
    margin: 0;
}

.info span {
    font-weight: 500;
}

.info span.highlight {
    color: #EE3439;
    font-size: 24px;
}

.info--status {
    flex-wrap: wrap;
}

.info--status span {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    color: #00B569;
    padding-left: 17px;
    margin-right: 16px;
}

.info--status span::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-image: url(../../assets/icon/Ok.png);
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 28%;
}

span.auction--success {
    color: #00B569;
    font-size: 20px;
    font-weight: 500;
}

.btn-link {
    border: 1px solid #515151;
    color: #515151;
    padding: 12px 32px;
    text-decoration: none;
    border-radius: 16px;
    font-weight: 500;
    display: inline-block;
}

.btn-auction {
    background-color: #C22924;
    color: #FFFFFF;
    border: none;
    margin-left: 15px;
}

.product_detail .more-info__ttl {
    font-weight: 500;
    font-size: 22px;
    line-height: 39.6px;
}

.product_detail .more-info li {
    padding: 5px 0;
}

.product_detail .list-info li {
    position: relative;
    margin-left: 33px;
}

.product_detail .list-info li::before {
    content: " ";
    background: url('@/assets/icon/Note.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    position: absolute;
    left: -33px;
    top: 3px;
}

.product_detail .list-info li a {
    text-decoration: none;
    color: #515151;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-info,
.list-info-cus {
    padding-left: 5px;
}

.product_detail .list-info-cus img {
    padding-right: 14px;
}

.product_detail .text-red {
    color: #EE3439;
    font-size: 16px;
    font-weight: 500;
    line-height: 28.8px;
    font-family: Roboto;
}

.show-time {
    position: relative;
    text-align: center;
    font-weight: 500;
    color: #EE3439;
    border: 1px solid #EE3439;
    border-radius: 30px;
}

.end-time {
    color: #EE3439;
    position: relative;
    text-align: center;
    font-weight: 500;
    box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background-color: #F7F7F7;
    ;
}

.show-time::before {
    content: "";
    position: absolute;
    background-image: url('../../assets/icon/clock.png');
    width: 75px;
    height: 61px;
    background-repeat: no-repeat;
    left: 0;
    top: 16px;
}

.show-time p:first-child {
    font-size: 22px;
}

.show-time p:last-child {
    font-size: 30px;
    font-family: Libre Bodoni;
    white-space: break-spaces;
}

.show-time p:nth-child(2) {
    font-size: 18px;
}

.show-time p {
    margin: 0;
}


@media (max-width: 1024px) {
    .info p {
        min-width: 240px;
    }

    .btn-link {
        padding: 12px 16px;
    }
}

@media (max-width: 768px) {
    .product_detail .title {
        font-size: 20px;
    }

    .product_detail .more-info,
    .product_detail .text-red {
        font-size: 14px;
    }

    .product_detail .more-info__ttl {
        font-size: 16px;
    }

    .info p {
        min-width: 330px;
    }

    .info span.highlight {
        font-size: 16px;
    }

    .btn-auction {
        margin-left: 0;
        margin-top: 10px;
    }
    .file .col-md-4 {
        width: 50%;
    }
}

@media (max-width: 575px) {
    .show-time::before {
        display: none;
    }
}
@media (max-width: 456px) {
    .file .col-md-4 {
        width: 100%;
    }

    .info span.highlight {
        font-size: 18px;
    }

    .info p {
        min-width: 210px;
    }

    .info {
        font-size: 14px;
    }

    .info--status {
        justify-content: space-between;
    }

    .show-time p:first-child {
        font-size: 18px;
    }

    .show-time p:nth-child(2) {
        font-size: 16px;
    }

    .show-time p:last-child {
        font-size: 22px;
    }

    .product_detail .title {
        font-size: 18px;
    }
    .status_au--fail::before{
        display: none;
    }
    .info--status span::before {
        display: none;
    }
    .btn-link{
        margin-top: 5px;
        min-width: 216px;
        text-align: center;
        margin-left: 15px;
    }
    .text-right.md {
        text-align: center !important;
    }
}

@media (max-width: 375px) {
    .info span.highlight {
        font-size: 16px;
    }
}

@media (max-width: 320px) {
    .info p {
        min-width: 200px;
    }

    .info span.highlight,
    .start-at {
        font-size: 14px;
    }

    .info,
    .product_detail .more-info,
    .product_detail .text-red {
        font-size: 14px;
    }

    span.auction--success {
        font-size: 16px;
    }
}

</style>