import JSEncrypt from "jsencrypt";

export default {
    methods: {
        
        async getListAssetForMember(params) {
            params.to_price = params.to_price.replace(/[^\d]/g, '')
            params.from_price = params.from_price.replace(/[^\d]/g, '')
            let token = ''
            if (localStorage.getItem('member')) {
                token = localStorage.getItem('member')
            }
            await this.$axios.get('/asset/getListAssetForMember', {
                params: params,
                headers: {
                    'x-access-token': token
                }
            }).then(res => {
                this.listAsset = res.data
                if (this.asset_id) {
                    this.listAsset.result = res.data.result.filter(product => product.asset_id != this.decode(this.asset_id))
                }
                this.carouselKey++;
                if (this.pageAsset) {
                    this.pageAsset.totalData = res.data.pagination.total_element
                    this.pageAsset.totalPage = res.data.pagination.total_page
                    this.pageAsset.currentPage = res.data.pagination.page
                    this.pageAsset.perPage = res.data.pagination.per_page
                }
            }).catch(err => {
                this.checkAuthenticate(err)
            })
        },
        encryptData(data) {
            // Tạo đối tượng JSEncrypt với khóa công khai
            const encryptor = new JSEncrypt();
            encryptor.setPublicKey(this.publicKey);

            // Mã hóa dữ liệu sử dụng khóa công khai RSA
            const encryptedData = encryptor.encrypt(data);

            // Lưu kết quả vào biến encryptedData để hiển thị trên giao diện
            return encryptedData;
        },
        async getListAuctionForMember(params){
            let token = ''
            if (localStorage.getItem('member')) {
                token = localStorage.getItem('member')
            }
            await this.$axios.get('/auction/getListAuctionForMember', {
                params: params,
                headers: {
                    'x-access-token': token
                }
            }).then(res => {
                this.listAuction = res.data
                if (this.pageAuction) {
                    this.pageAuction.totalData = res.data.pagination.total_element
                    this.pageAuction.totalPage = res.data.pagination.total_page
                    this.pageAuction.currentPage = res.data.pagination.page
                    this.pageAuction.perPage = res.data.pagination.per_page
                }
            }).catch(err => {
                this.checkAuthenticate(err)
            })
            
        },
        
        async getListNews(params) {
            let token = ''
            if (localStorage.getItem('member')) {
                token = localStorage.getItem('member')
            }
            await this.$axios.get('/news/getListNews', {
                params: params,
                headers: {
                    'x-access-token': token
                }
            }).then(res => {
                this.listNews = res.data
                if (this.pageNews) {
                    this.pageNews.totalData = res.data.pagination.total_element
                    this.pageNews.totalPage = res.data.pagination.total_page
                    this.pageNews.currentPage = res.data.pagination.page
                    this.pageNews.perPage = res.data.pagination.per_page
                }
            }).catch(err => {
                this.checkAuthenticate(err)
            })
        },
        async getListCategoryForMember(params) {
            let token = ''
            if (localStorage.getItem('member')) {
                token = localStorage.getItem('member')
            }
            await this.$axios.get('/category/getListCategoryForMember', {
                params: params,
                headers: {
                    'x-access-token': token
                }
            }).then(res => {
                this.listCategory = res.data.result
            }).catch(err => {
                this.checkAuthenticate(err)
            })
        },
        getCategoryDetail(params) {
            this.listCategory.forEach(e => {
                if (e.category_id == params.category_id) {
                    this.categoryDetail = e
                    this.items.forEach(item => {
                        if (item.active) {
                            item.text = this.categoryDetail.name
                        }
                    })
                }
            });
        },
        async getAuctionDetailForMember(params) {
            let token = ""
            if (localStorage.getItem('member')) {
                token = localStorage.getItem('member')
            }
            await this.$axios.get('/auction/getAuctionDetailForMember', {
                params: params,
                headers: {
                    'x-access-token': token
                }
            }).then(res => {
                this.auctionDetail = res.data
            }).catch(err => {
            })
        },
        async changeAvatar(e) {
            await this.$axios.post('/common/uploadFile', {
                file: e.target.files[0],
                path: 'member/avatar/',
            }, {
                headers: {
                    'x-access-token': this.info.accessToken,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                this.$refs.avatar.src = this.$storage + '/' + res.data.list_result[0].path_tam
            }).catch((err) => {
                this.checkAuthenticate(err)
            })
        },
        async getDataMember() {
            await this.$axios.get('/member/getMemberDetail?member_id=' + this.info.id, {
                headers: {
                    'x-access-token': this.info.accessToken
                }
            }).then((res) => {
                this.dataMember = res.data
            }).catch((err) => {
                this.checkAuthenticate(err)
            })
        },
        async __changeCity(event) {
            this.dataDistricts = await this.callApiDistrict(event.target.value)
        },
        async __changeDistrict(event) {
            this.dataWard = await this.callApiWard(event.target.value)
        },
        async updateMember(params) {
            event.preventDefault()
            if (this.valueYear&&this.valueMonth&&this.valueDay){
                params.birthday = this.birthday[2] + "-" + this.birthday[1] + '-' + this.birthday[0]
            }
            if (this.formData.id_front_face) {
                params.id_front_face = this.formData.id_front_face
            }
            if (this.formData.id_back_side) {
                params.id_back_side = this.formData.id_back_side
            }
            if (!this.formData.gender) {
                params.gender = "0"
            }
            if (!params.file_path) {
                params.file_path = []
                this.formData.file.forEach(element => {
                    params.file_path.push(element.url)
                });
            }
            await this.$axios.put('/member/updateMember', params, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('member'),
                }
            }).then(res=>{
                this.$toast.success('Thành công!')
                this.$refs.editAccount.hide()
                window.location.reload();
            }).catch(err => {
                if (err.code == "ERR_NETWORK") {
                    this.$toast.error('Lỗi hệ thống')
                } else {
                    this.$toast.error(err.response.data.error)
                }
            })
        },
        async updateBankInfo(params) {
            await this.$axios.put('/member/updateBankInfo ', {
                bank_account_name: params.bank_account_name,
                bank_account_number: params.bank_account_number,
                bank_id: params.bank_id,
                branch: params.branch,
                password: this.encryptData(params.password)
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('member'),
                }
            }).then(res => {
                this.$toast.success('Thành công!')
                this.$refs.editBanking.hide()
                window.location.reload();
            }).catch(err => {
                if (err.code == "ERR_NETWORK") {
                    this.$toast.error('Lỗi hệ thống')
                } else {
                    this.$toast.error(err.response.data.error)
                }
            })
        },
        async getListMissingRequiredFile(params){
            await this.$axios.get('/auction/getListMissingRequiredFile', {
                params: params,
                headers: {
                    'x-access-token': localStorage.getItem('member')
                }
            }).then(res => {
                this.listMissingRequiredFile = res.data
                this.listMissingRequiredFile.forEach(element => {
                    this.listFile.push({
                        required_file_id: element.required_file_id,
                        url:''
                    })
                });
                if (this.page) {
                    this.page.totalData = res.data.pagination.total_element
                    this.page.totalPage = res.data.pagination.total_page
                    this.page.currentPage = res.data.pagination.page
                    this.page.perPage = res.data.pagination.per_page
                }
                
            }).catch(err => {
                this.checkAuthenticate(err)
            })
        },
        async __uploadFile(event) {
            let name = event.target.name
            let files = Array.from(event.target.files)
            if(name == 'file_path') {
                this.formData[name] = []
                files.forEach((e,index) => {
                    this.$axios.post('/common/uploadFile', {
                        file: e,
                        path: 'member/file/',
                    }, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res) => {
                        this.formData[name].push(res.data.list_result[0].path)
                    })
                });
            } else {
                files.forEach((e,index) => {
                    this.$axios.post('/common/uploadFile', {
                        file: e,
                        path: 'member/' + name + '/',
                    }, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res) => {
                        if (event.target.accept == 'image/png, image/gif, image/jpeg, image/jpg') {
                            this.$refs[name].src = this.$storage + '/' + res.data.list_result[0].path_tam
                        }
                        this.formData[name] = res.data.list_result[0].path
                    })
                });
            }
        },
        checkAuthenticate(err){
            console.log('err', err.code);
            if (err.code == "ERR_NETWORK") {
                this.$toast.error('Lỗi hệ thống')
                this.$router.push('/networkError')
            }
            else if (err.response.status == 409 ) {
                this.logoutMember()
                this.$toast.warning("Phiên đăng nhập đã hết!")
                this.$router.push('/login')
            }
            else if (err.response.status == 401) {
                localStorage.removeItem('userInfo')
                localStorage.removeItem('member')
                this.$router.push('/')
                window.location.reload();
            }  else {
                this.$toast.error(err.response.data.error)
            }
        },
        async logoutMember() {
            localStorage.removeItem('userInfo')
            localStorage.removeItem('member')
            this.logoutUser();
            this.$router.push('/')
            window.location.reload();
            await this.$axios.post('/member/signoutMember', this.formData, {
                headers: {
                    'x-access-token': localStorage.getItem('member'),
                },
            }).then(res => {
                
            })
        },
        toPdf(url) {
            window.open(this.$storage + url, '_blank');
        },
    }

}