<template>
    <div class="InternetNotFound"></div>
</template>
<script>
export default {
    name: "InternetNotFound",
    mounted(){
        window.addEventListener('online', () => 
        {
            console.log('online');
            this.$router.push('/')
        }
    );
    }
};
</script>
<style lang="css" scoped>
    .InternetNotFound {
        width: 100%;
        height: 100vh;
        position: relative;
    }
    .InternetNotFound::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(../../assets/images/network_error.png) no-repeat;
        background-position: center;
        background-size: cover;
    }
    

</style>
